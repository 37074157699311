import React, { useState, useEffect, useContext } from "react"
import { graphql } from "gatsby"

import "../../styles/pages/glasses.scss"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import GlassesHeading from "../../components/GlassesHeading"
import Breadcrumbs from "../../components/Breadcrumbs"
import GlassesFilter from "../../components/GlassesFilter"
import GlassesList from "../../components/GlassesList"
import ProductBanner from "../../components/ProductBanner"
import { useDebounce, useGlasses } from "../../services/hooks"
import CartContext from "../../context/cartContext"

const Glasses = ({ data, location }) => {
  const hubbleFrames = data.hubbleapi.hubbleFrameProducts.filter(
    ({ collectionString }) => {
      const collectionArray = collectionString.split(",")

      return (
        collectionArray.includes("Hubble") &&
        !collectionArray.includes("Modern")
      )
    }
  )


  const right_side_text = "Quality, comfort, style: yes you can have it all. Every pair of our unisex glasses frames comes standard with impact-resistant lenses, durable barrel hinges, and padded temple tips for extra cush."
  const list_items = [
    "Quality first. Gender neutral glasses that don’t sacrifice function over form. Anti-reflective, anti-glare, anti-scratch, hydrophobic and oleophobic coatings come standard on every pair.",
    "Comfort Matters. Keep it extra comfy all day with padded temple tips and durable barrel hinges.",
    "Personalized just-for-you. Frames by Hubble are always made to order and assembled by hand. Plus, add blue-light filtering and reader magnification to any of our unisex glasses frames.",
    "Designed for you. Break boundaries and discover your perfect pair of frames with personality. Our signature unisex eyeglasses collections are developed in-house and brings out the inner visionary in all of us.",
  ]

  const { setHeaderColor } = useContext(CartContext)

  const defaultFilter = {
    price: [],
    width: [],
    shape: [],
    material: [],
    color: [],
    gender: ["unisex"],
  }

  const params = new URLSearchParams(location.search)

  params.forEach((value, key) => {
    defaultFilter[key] = value.split(",")
  })

  const [filter, setFilter] = useState(defaultFilter)

  const debouncedFilter = useDebounce(filter, 500)

  useEffect(() => {
    const params = new URLSearchParams(location.search)

    params.forEach((value, key) => {
      defaultFilter[key] = value.split(",")
    })

    setFilter(defaultFilter)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    if (!debouncedFilter) return

    // navigateWithFilters(location.pathname, debouncedFilter)
  }, [debouncedFilter])

  useEffect(() => {
    setHeaderColor("orange")

    return () => {
      setHeaderColor("black")
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const sortedGlasses = useGlasses(hubbleFrames, debouncedFilter)

  useEffect(() => {
    if (typeof window !== undefined ) {
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: "view_item_list",
        ecommerce: {
          items: sortedGlasses.map(( product, index ) => (
            {
              index: index,
              item_id: product.shopifyProductId,
              item_name: product.title,
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "frames",
              item_category2: 'optical',
              item_category3: product.productGender,
              item_category4: product.material,
              item_category5: product.size,
              item_list_id: `${product.productGender}-Optical-Unisex`,
              price: parseFloat(product.glassesBasePrice),
            }
          ))
        }
      })
    }
  }, [sortedGlasses])

  const schemaMarkup = [{
    "@url": "https://hubblecontacts.com/glasses/unisex-sunglasses",
    "@type": "webpage",
    "name": "Unisex Glasses: Prescription Glasses Online | Hubble",
    "description": "Shop unisex glasses frames from Hubble, especially curated to look amazing on every face. With our wide range of styles and sizes, you'll find everything from timeless designs to modern looks.",
    "publisher": {
      "@type": "Organization",
      "name": "Hubble Contacts"
    }
  }]

  return (
    <Layout className="glasses">
      <SEO
        title="Unisex Glasses: Prescription Glasses Online"
        description="Shop unisex glasses frames from Hubble, especially curated to look amazing on every face. With our wide range of styles and sizes, you'll find everything from timeless designs to modern looks."
        schemaMarkup={schemaMarkup} />
      <GlassesHeading
        color="orange"
        title="Unisex Glasses"
        description="Your glasses should be as unique as you are. Our unisex eyeglasses are
                      specially curated to look fabulous on absolutely everyone, with each pair
                      offering its own distinct touch of personal flair. From traditional timeless
                      designs to delightfully modern takes on the classics, you’ll find the unisex
                      eyeglass frame you’re looking for here. "
      />
      <div className="glasses-container">
        <div className="grid-section">
          <Breadcrumbs
            color="orange"
            links={[{ to: "/", label: "Homepage" }, { label: "Eyeglasses" }]}
          />
          <GlassesFilter
            type="glasses"
            glassesLength={sortedGlasses.length}
            filter={filter}
            setFilter={setFilter}
          />
          <GlassesList
            type="glasses"
            glasses={sortedGlasses}
            color={filter.color}
            gender={filter.gender[0]}
          />
          <ProductBanner
            title={"The best unisex eyeglasses, starting at just $49.99."}
            right_side_text={right_side_text}
            list_items={list_items}
            optional_image="Hubble-Glasses-PCP-Inset-Lifestyle-Image.jpg"
            optional_image_alt_text="Woman wearing Hubble eyeglasses while enjoing time at home"
            color="orange"
          />
        </div>
      </div>
    </Layout>
  )
}

export default Glasses

export const query = graphql`
  query {
    hubbleapi {
      hubbleFrameProducts {
        title
        handle
        shopifyProductId
        description
        collectionString
        glassesBasePrice
        sunglassesBasePrice
        sunglassesAvailable
        productGender
        maleHeaderImage1
        maleHeaderImage2
        size
        shape
        femaleHeaderImage1
        femaleHeaderImage2
        productImage
        material
        materialFilter
        productClothImage
        sortOrder
        relatedFrames {
          edges {
            node {
              title
              productImage
              hubbleFrameVariants {
                title
                color
                colorFilter
                heroColor
                productImage1
                productImage2
                sunglassesWideImage1
                sunglassesWideImage2
                sunglassesWideImage3
                sunglassesProductImage1
                sunglassesProductImage2
                sunglassesProductImage3
                sunglassesProductImage4
              }
            }
          }
        }
        hubbleFrameVariants {
          title
          shopifyVariantId
          color
          colorFilter
          sku
          heroColor
          availableForSale
          productImage1
          productImage2
          productImage3
          sunglassesWideImage1
          sunglassesWideImage2
          sunglassesWideImage3
          sunglassesProductImage1
          sunglassesProductImage2
          sunglassesProductImage3
          sunglassesProductImage4
        }
      }
    }
  }
`
